@import "../../base.scss";

.calculator-wall-thickness {
    .calculator {
        .row-bttn {
            padding-top: 20px;
            padding-bottom: 20px;
            text-align: right;
            .bttn {
                width: auto;
                font-size: $font-size;
                padding-left: 30px;
                padding-right: 30px;
                background-color: rgba(255, 255, 255, 0.25);
            }
        }

        .section {
            display: block;
            background-color: #545454;
            padding: 30px 60px;
            margin-bottom: 20px;
            @include box-shadow($box-shadow-2);
            border-radius: $border-radius-sm;
            &:last-child {
                margin-bottom: 0;
            }

            @media all and (max-width: $getscreen-mobile) {
                padding: 40px;
            }
            @media all and (max-width: 768px) {
                padding: 20px;
            }

            > .row {
                margin-left: -30px;
                margin-right: -30px;

                @media all and (max-width: $getscreen-mobile) {
                    flex-direction: column;
                }

                > .col {
                    padding-left: 30px;
                    padding-right: 30px;
                    &:nth-child(1) {
                        width: 40%;
                    }
                    &:nth-child(2) {
                        width: 60%;
                    }

                    @media all and (max-width: 1100px) {
                        &:nth-child(1) {
                            width: 50%;
                        }
                        &:nth-child(2) {
                            width: 50%;
                        }
                    }
                    @media all and (max-width: $getscreen-mobile) {
                        &:nth-child(1),
                        &:nth-child(2) {
                            width: 100%;
                        }
                        &:nth-child(1) {
                            padding-bottom: 60px;
                        }
                    }
                }
            }
            .col {
                flex-basis: auto;
                flex-grow: initial;
                &.col-1 {
                    width: 50%;
                }
                &.col-2 {
                    width: 30%;
                }
            }
            .row {
                .row {
                    .col {
                        display: flex;
                        align-items: center;
                    }
                }
            }

            .form-row {
                padding-bottom: 16px;
                min-height: $form-control-height;
               
                &:last-child {
                    padding-bottom: 0;
                }

                @media all and (max-width: 480px) {
                    flex-wrap: wrap;
                    flex-direction: row;
                }


                .col {
                    @media all and (max-width: $getscreen-mobile) {
                        &.col-1 {
                            width: 55%;
                        }
                        &.col-2 {
                            width: 35%;
                            .select-box {
                                width:100%;
                            }
                        }
                        &.col-3{
                            padding-left: 0;
                            justify-content: flex-end;
                            width: 10%;
                        }
                    }

                    @media all and (max-width: 480px) {
                        &.col-1 {
                            width: 100%;
                        }
                        &.col-2 {
                            width: 85%;
                            .select-box {
                                width:100%;
                            }
                        }
                        &.col-3{
                            padding-left: 0;
                            justify-content: flex-end;
                            width: 15%;
                        }
                    }
                }
            }

            h4 {
                font-family: $font-family-bold;
                font-size: 20px;
                line-height: 1.4em;
                margin: 0;
                padding: 0 0 20px 0;
            }
            > h4 {
                padding-bottom: 40px;
            }

            .label {
                display: block;
                font-size: $font-size-sm;
            }
            .opacity {
                opacity: 0.5;
            }

            // .select-box {
            //     min-width:150px;
            // }
        }
    } /* end calculator */
}
