@import "./base.scss";

/* BASE */
body {
  color: $txt-color;
  font-size: $font-size;
  font-family: $font-family-regular;
  background-color: $body-bg;
  overflow: hidden;
}
.wrapall {
  overflow: hidden;
}
@media all and (max-width: $getscreen-mobile) {
  html {
    scroll-behavior: smooth;
  }
  body {
    overflow: auto;
    background-color: $body-bg-m;
  }
  .wrapall {
    overflow: auto;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
form,
section {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-header;
}
h1 {
  padding-bottom: 20px;
  font-size: $font-size-xl;
  font-weight: normal;
}
h2 {
  padding-bottom: 20px;
  font-size: $font-size-lg;
  font-weight: normal;
  line-height: 1.2em;
}
h3 {
  padding-bottom: 24px;
  font-size: $font-size-md;
  font-family: $font-family-regular;
  font-weight: normal;
  small {
    display: block;
    font-size: $font-size-xxs;
    padding-top: 5px;
    font-family: $font-family-regular;
    line-height: 1.4em;
  }
}
h4 {
  font-size: $font-size;
  font-weight: normal;
  small {
    display: block;
    font-size: $font-size-xxs;
    padding-top: 5px;
    font-family: $font-family-regular;
    line-height: 1.4em;
  }
}

a {
  color: $txt-color;
  @include transition($animation);
}
a {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}

/*special hover*/
a.hover {
  position: relative;
  display: inline-block;
  text-decoration: none;
  @include transition($animation);
  &:before {
    content: "";
    position: absolute;
    overflow: hidden;
    margin: auto;
    height: 1px;
    bottom: -3px;
    left: 0;
    right: 0;
    border-bottom: 1px solid #ffffff;
    width: 0px;
    opacity: 0;
    @include transition($animation);
  }
  &:hover {
    text-decoration: none;
    @include transition($animation);
    &:before {
      width: 100%;
      opacity: 1;
    }
  }
}
/*end special hover*/
p {
  padding-bottom: 24px;
  line-height: 1.7em;
}
p:last-child {
  padding-bottom: 0;
}

ul li {
  list-style: none;
}
ol {
  list-style-position: inside;
}
.list-style {
  li {
    margin: 0;
    padding: 0 0 14px 0;
    &:before {
      content: "\00B7";
      font-size: 24px;
      padding-right: 10px;
      line-height: 1em;
      display: inline-block;
      margin-left: -14px;
    }
  }
}

b,
.bold,
.font-bold {
  font-family: $font-family-bold;
}
.font-medium {
  font-family: $font-family-medium;
}
.font-regular {
  font-family: $font-family-regular;
}
.font-black {
  font-family: $font-family-black;
}
.font-light {
  font-family: $font-family-light;
}
.font-thin {
  font-family: $font-family-thin;
}

.animation {
  @include transition($animation);
}
.txt-shadow {
  @include text-shadow($txt-shadow);
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.txt-center {
  text-align: center;
}
.txt-left {
  text-align: left;
}
.txt-right {
  text-align: right;
}
.txt-justify {
  text-align: justify;
}

.align-left {
  display: flex;
  justify-content: flex-start;
}
.align-right {
  display: flex;
  justify-content: flex-end;
}
.align-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.align-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.align-space-around {
  display: flex;
  justify-content: space-around;
}
.align-space-between {
  display: flex;
  justify-content: space-between;
}
/*end BASE*/

/*LAYOUT*/

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-bg {
  z-index: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  &:after {
    content: "";
    z-index: 1;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $overlay;
    overflow: hidden;
  }

  @media all and (max-width: $getscreen-mobile) {
    position: relative;
    height: auto;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto 100vh;
    &:after {
      max-height: 100vh;
    }
  }
}

.container {
  z-index: 3;
  position: absolute;
  width: $container-width;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  @extend %flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: $header-height;
  // padding-left: $sidespace;
  // padding-right: $sidespace;

  @media (max-width: $bp-responsive) {
    width: 98%;
  }
  @media (max-width: $getscreen-mobile) {
    width: $container-width-m;
  }
}
.container-full {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  @extend %flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: $header-height;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/*content-boxes*/

.content {
  position: relative;
  background-color: $content-bg;
  padding: $sidespace-md;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 100%;
  border-top-left-radius: $border-radius-sm;
  border-top-right-radius: $border-radius-sm;
  &.content-box-2 {
    width: 43%;
    padding: $sidespace-lg;
  }
}

.row {
  @extend %flex;
  margin-left: -15px;
  margin-right: -15px;
}
.col {
  @extend %col;
  flex-basis: 0;
  flex-grow: 1;
  padding-left: 15px;
  padding-right: 15px;
}

.scroll {
  position: relative;
  height: 100%;
  margin-left: -15px;
  margin-right: -15px;
}
.scrollbar-container {
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
}

/* MOBILE */
.container-m {
  position: relative;
  z-index: 10;
  padding-top: $header-height-m;
  margin: auto;
  @media all and (max-width: $bp-mobile) and (orientation: landscape) {
    padding-top: $header-height-sm;
  }
}
.content-m {
  position: relative;
  z-index: 3;
  padding-left: $sidespace;
  padding-right: $sidespace;

  @media (max-width: $bp-mobile-sm) {
    padding-left: $sidespace-sm;
    padding-right: $sidespace-sm;
  }
}
/*end MOBILE*/

/*end LAYOUT*/

/*MAIN*/

.breadcrumbs {
  @extend %flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;
  font-size: $font-size-sm;
  line-height: 1.2em;

  a,
  span {
    display: inline-block;
    text-decoration: none;
    &.opacity {
      opacity: 0.5;
    }
  }

  > a:before,
  > span:before {
    display: inline-block;
    content: "<";
    padding-right: 10px;
    padding-left: 10px;
  }

  .link-back {
    @extend %flex;
    flex-direction: row;
    align-items: center;
    opacity: 1;
    &:before {
      content: "\e901";
      font-family: "icomoon";
      color: #ffffff;
      font-size: $font-size-lg;
      opacity: 1;
      padding-left: 0;
    }
  }
}

/*slick slider */
.slick-slider {
  height: 100%;
  .slick-list {
    height: 100%;
    .slick-track {
      height: 100%;
      .slick-slide {
        height: 100%;
        > div {
          height: 100%;
          > div {
            height: 100%;
          }
        }
      }
    }
  }
}
/*end slick slider */

/*end MAIN*/

/*FORMS*/
::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

[type="checkbox"],
[type="radio"] {
  padding: 0;
  margin: 0;
}

.label {
  font-size: $font-size-sm;
  display: block;
  font-family: $font-family-medium;
  padding-bottom: 5px;
  small {
    display: block;
    font-family: $font-family-thin;
    font-size: $font-size-xxs;
  }
}
.input,
.textarea,
.select,
.react-select {
  margin: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0.5px solid #ffffff;
  color: $txt-form-color;
  font-size: $font-size;
  font-family: $font-family-regular;
  background-color: transparent;
  border-radius: 0;
}

.select,
.react-select {
  height: $form-control-height;
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}
/* For IE10 */
select.select::-ms-expand,
select.react-select::-ms-expand {
  display: none;
}
option {
  border-radius: 0;
}
.select-box {
  width: 100%;
}
.react-select {
  > div,
  * :focus,
  * :active {
    background: transparent;
    border-radius: 0;
    border: 0;
    box-shadow: none;
  }

  [class^="-control"],
  [class*="-control"] {
    background-color: transparent;
    color: #ffffff;
    * {
      color: #ffffff;
    }
    > div {
      padding: 0;
    }
  }
  [class^="-ValueContainer"],
  [class*="-ValueContainer"] {
    opacity: 0.7;
  }
  [class^="-indicatorSeparator"],
  [class*="-indicatorSeparator"] {
    display: none;
  }
  [class^="-IndicatorsContainer"],
  [class*="-IndicatorsContainer"] {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  [class^="-menu"],
  [class*="-menu"] {
    background-color: #545454;
    color: #ffffff;
    @include box-shadow($box-shadow-2);
  }
  [class^="-option"]:hover,
  [class*="-option"]:hover {
    background-color: #434141;
    color: #ffffff;
  }
}

.input {
  height: $form-control-height;
  padding: 0;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ffffff;

  &.input-code {
    max-width: 55px;
    text-align: center;
    font-size: $font-size-lg;
    color: rgba(255, 255, 255, 0.5);
  }
}
.textarea {
  padding: 0 8px;
  width: 100%;
  border: 0;
  border-bottom: 2px solid lightgrey;
}

.bttn {
  cursor: pointer;
  margin: 0;
  padding: 0 20px;
  border: 0;
  height: $form-control-height;
  background-color: #ffffff;
  color: $txt-color-2;
  font-family: $font-family-medium;
  font-size: $font-size;
  border-radius: $border-radius-sm;
  width: 100%;
  .icon {
    color: $txt-color-2;
  }
  &.inactive {
    opacity: 0.7;
  }
}

a.bttn {
  @extend %flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

/* checkbox switcher */
.switcher {
  position: relative;
  display: block;
  width: 30px;
  height: 18px;
  border-radius: 9px;
  cursor: pointer;

  .box {
    border: 1px solid #ffffff;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    top: 0;
    left: 0;
    @include transition($animation);
    .check {
      position: absolute;
      display: block;
      top: 1px;
      left: 1px;
      width: 14px;
      height: 14px;
      background-color: #ffffff;
      border-radius: 14px;
      @include box-shadow($box-shadow);
      @include transition($animation);
    }
  }
  input {
    opacity: 0;
  }
  input:checked ~ .box {
    background-color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.5);
    .check {
      top: 1px;
      left: 13px;
    }
  }
}
/*end checkbox switcher */

/* radio */
.radio {
  position: relative;
  display: inline-block;
  cursor: pointer;
  line-height: 1em;

  .box {
    position: relative;
    display: inline-block;
    @include transition($animation);
    padding-left: 25px;
    font-size: $font-size;
    line-height: 1.4em;

    &:before {
      position: absolute;
      top: 1px;
      left: 0;
      content: "";
      width: 18px;
      height: 18px;
      border-radius: 9px;
      border: 1px solid #ffffff;
      @include box-shadow($box-shadow-2);
      @include transition($animation);
    }
  }
  input {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  input:checked ~ .box {
    &:before {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}
/*end radio */

.form-row {
  padding-bottom: 15px;
  @media all and (max-width: $getscreen-mobile) {
    padding-bottom: 45px;
  }
}
.form-row-bttn {
  padding-top: 10px;
}

/* end FORMS*/

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}
.react-responsive-modal-overlay {
  background: none !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
